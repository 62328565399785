import React from 'react'
import DataGrid, { RowRendererProps, Row } from 'react-data-grid'

import { CreditRow, DebitRow } from 'components/transactionGrid/style'
import { Button, Grid } from '@material-ui/core'
import { TransactionObject } from '../../types'

interface TransactionGridProps {
  transactions: TransactionObject[]
  dataPullVisible: boolean
  dataPullFunction: CallableFunction
  style: any
  loading: boolean
}

const TransactionGrid: React.FC<TransactionGridProps> = ({
  transactions,
  dataPullVisible,
  dataPullFunction,
  style,
  loading
}: TransactionGridProps) => {
  const columns = [
    { key: 'name', name: 'Name' },
    { key: 'amount', name: 'Amount' },
    { key: 'date', name: 'Date' }
  ]
  if (loading) return <p>Loading...</p>
  else
    return (
      <div>
        <DataGrid
          style={style}
          className="rdg-light"
          columns={columns}
          rows={transactions}
          rowRenderer={MyRowRenderer}
        />

        {!dataPullVisible && (
          <Grid container justify="flex-end">
            <Button
              onClick={() => dataPullFunction()}
              style={{ marginTop: '10px' }}
              variant="contained"
              color="primary"
            >
              Pull more data
            </Button>
          </Grid>
        )}
      </div>
    )
}

TransactionGrid.defaultProps = {
  transactions: [],
  dataPullVisible: false,
  dataPullFunction: () => {
    return
  },
  style: {},
  loading: false
}

export default TransactionGrid

function MyRowRenderer(props: RowRendererProps<TransactionObject>) {
  if (props.row.amount < 0) {
    return (
      <CreditRow>
        <Row {...props} className={'credit'} />
      </CreditRow>
    )
  } else {
    return (
      <DebitRow>
        <Row {...props} className={'debit'} />
      </DebitRow>
    )
  }
}
