import React from 'react'
import CustomDialog from 'components/dialog'
import { useApplicationStore } from 'hooks/application'
import ExpressionBuilder from '../expressionBuilder'
import { RuleObject } from '../../types'
import { QueryBuilder } from '../queryBuilder'
import { ViewDialogOverflow } from './style'

interface RulesBoxProps {
  title: string
  open: boolean
  data: RuleObject
  handleClose: () => void
  handleSave: () => void
}

const RulesBox: React.FC<RulesBoxProps> = ({ title, open, data, handleClose, handleSave }: RulesBoxProps) => {
  const { investments, liabilities } = useApplicationStore()

  const conditionFunctions = [
    {
      value: 'SPLIT (dim, delimiter, occurrence_number)',
      label: 'SPLIT',
      type: 'function',
      key: 'split',
      keyLabel: 'f(x)',
      params: ['dim', 'delimiter', 'occurrence_number'],
      helper: 'Returns the nth substring divided by a specified delimiter. Index, n, starts from 0'
    },
    {
      value: 'CONCAT (dim1, dim2)',
      label: 'CONCAT',
      key: 'concat',
      params: ['dim1', 'dim2'],
      type: 'function',
      keyLabel: 'f(x)',
      helper: 'Returns the concatenation of two strings.'
    },
    {
      value: 'SUB (dim, starting_at, ending_at)',
      label: 'SUB',
      key: 'sub',
      params: ['dim', 'starting_at', 'ending_at'],
      type: 'function',
      keyLabel: 'f(x)',
      helper: 'Returns a substring between specified character indices. Index starts from 0'
    },
    {
      value: 'EXTRACT (dim, prefix_string, suffix_string)',
      label: 'EXTRACT',
      key: 'extract',
      params: ['dim1', 'prefix_string', 'suffix_string'],
      type: 'function',
      keyLabel: 'f(x)',
      helper: 'Returns a substring between the first prefix_string and first suffix_string'
    }
  ]
  const accountVariables = [...investments, ...liabilities].map((account) => {
    return {
      value: account.account_id,
      label: account.name,
      type: 'dim',
      keyLabel: 'dim',
      key: account.account_id,
      helper: `Account ${account.name}`
    }
  })
  const conditionVariables = [...accountVariables]
  const actionVariables = [...accountVariables]
  // regex to match entires within ""
  const stringRegex = /"([^\\"]|\\")*"/

  // Optional - Function which validates all the input values and returns a bool.
  const validationFn = (val) => {
    return !isNaN(val) || stringRegex.test(val)
  }

  return (
    <CustomDialog
      customClass="MuiDialog-paper-expression"
      title={title}
      open={open}
      maxWidth={'lg'}
      handleClose={handleClose}
      handleSave={handleSave}
    >
      <div style={{ padding: '20px' }}>
        <label>Condition</label>
        <QueryBuilder></QueryBuilder>
        <label>Action</label>
        <ExpressionBuilder
          onChangeFn={(editorState) => (data.action = editorState)}
          options={[...conditionFunctions, ...actionVariables]}
          expressionRootClass={'expression-builder-root'} // Optional
          expressionInputClass={'expression-builder-input'} // Optional
          placeholder="Expression" // Optional
          initialFocus={false} // if you want your input to be focussed on mount by default
          validationFn={validationFn} // Optional
        />
      </div>
      <style
        dangerouslySetInnerHTML={{
          __html: `.MuiDialog-paper-expression { overflow-y: visible !important; top: 50px !important; position: fixed !important;}`
        }}
      />
    </CustomDialog>
  )
}

export default RulesBox
