import { PropTypes, Tooltip } from '@material-ui/core'
import * as firebase from 'firebase/app'
import { icon } from 'assets'
import React, { useState } from 'react'
import { usePlaidLink } from 'react-plaid-link'

require('firebase/functions')
const createAssetToken = firebase.functions().httpsCallable('createAssetToken')
// LINK COMPONENT
// Use Plaid Link and pass link token and onSuccess function
// in configuration to initialize Plaid Link
interface LinkProps {
  linkToken: string | null
  addAccount: any | null
}
const Link: React.FC<LinkProps> = (props: LinkProps) => {
  const onSuccess = React.useCallback((public_token, metadata) => {
    createAssetToken({ public_token: public_token }).then((result) => {
      // Read result of the Cloud Function.
      const sanitizedMessage = result.data
      console.log(sanitizedMessage)
      props.addAccount(sanitizedMessage.link_token)
    })
  }, [])
  const config: Parameters<typeof usePlaidLink>[0] = {
    token: props.linkToken!,
    onSuccess
  }
  const { open, ready } = usePlaidLink(config)
  return (
    <div style={{ marginLeft: 'auto', cursor: 'pointer' }} onClick={() => open()}>
      <Tooltip style={{ userSelect: 'none' }} title="Link New Account">
        <img src={icon.link} style={{ width: '50px', height: '50px' }} alt="link new account" />
      </Tooltip>
    </div>
  )
}
export default Link
