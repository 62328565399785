import React, { useState, useEffect } from 'react'
import { DashboardContainer, TitleRow, StatsRow, DashboardContent } from 'style'
import { useApplicationStore } from 'hooks/application'
import { IncomeRow } from 'views/income/style'
import Tooltip from '@material-ui/core/Tooltip'
import { formatNumber } from 'helpers/utils'
import { icon } from 'assets'
import { CURRENCY_CODES } from 'helpers/const'
import RulesBox from 'components/rulesBox'
import FinanceCard from 'components/financeCard'
import { IncomeObject, RuleObject } from 'types'
import Backdrop from '@material-ui/core/Backdrop'
import CircularProgress from '@material-ui/core/CircularProgress'
import MobileMenu from 'components/mobileMenu'

const Income: React.FC = () => {
  const { monthlyIncome, currency, income, addNewRule, editRuleArray, thisYear, endDate, age, rules, loading } =
    useApplicationStore()

  const ruleDefault = { condition: null, action: null, arrayPosition: 0, open: false }
  const [addRule, setAddRule] = useState<RuleObject>(ruleDefault)
  const [editRule, setEditRule] = useState<RuleObject>(ruleDefault)

  const saveNewRule = () => {
    addNewRule(addRule)
    setAddRule(ruleDefault)
  }
  const saveEditRule = () => {
    const newRuleArray = [...income]
    newRuleArray[editRule.arrayPosition] = {
      condition: editRule.condition,
      action: editRule.action
    }

    editRuleArray(newRuleArray)
    setEditRule({ ...editRule, open: false })
  }

  const deleteIncome = (number: number) => {
    const newIncomeArray = [...income]
    newIncomeArray.splice(number, 1)
    editRuleArray(newIncomeArray)
  }

  return (
    <DashboardContainer>
      <TitleRow>
        <MobileMenu />
        <div>Rules</div>
        <div style={{ marginLeft: 'auto', cursor: 'pointer' }} onClick={() => setAddRule({ ...addRule, open: true })}>
          <Tooltip style={{ userSelect: 'none' }} title="Add New Rule">
            <img src={icon.add} style={{ width: '50px', height: '50px' }} alt="add rule" />
          </Tooltip>
        </div>
      </TitleRow>

      <StatsRow>{`You currently make ${CURRENCY_CODES[currency].symbol}${formatNumber(
        monthlyIncome
      )} per month (equivalent to ${CURRENCY_CODES[currency].symbol}${formatNumber(
        monthlyIncome * 12
      )} per year) from your income sources.`}</StatsRow>

      <DashboardContent>
        <IncomeRow></IncomeRow>
      </DashboardContent>

      <RulesBox
        title={'Add Rule'}
        open={addRule.open}
        data={addRule}
        handleClose={() => setAddRule({ ...addRule, open: false })}
        handleSave={() => saveNewRule()}
      />

      <RulesBox
        title={'Add Rule'}
        open={addRule.open}
        data={addRule}
        handleClose={() => setEditRule({ ...editRule, open: false })}
        handleSave={() => saveEditRule()}
      />
      <Backdrop style={{ zIndex: 100, color: '#fff' }} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </DashboardContainer>
  )
}

export default Income
