import styled from 'styled-components'
import { SIDEBAR_BREAKPOINT } from 'style'

export const ExpressionRoot = styled.div`
  color: rgb(3, 102, 0);
  :focus {
    background: pink;
  }
`

export const DownshiftSelect = styled.input`
  border: 0px;
  outline: 0px;
  width: 100%;
  color: rgb(26, 26, 26);
  height: 2rem;
  border-radius: 0.4rem;
  margin: 0px 0.6rem;
  background-color: inherit;
  padding: 0px 0.4rem;
  font-size: 1vw;
  line-height: 2rem;
  ::placeholder {
    font-size: 1vw;
  }
`

export const ExpressionBuilderRoot = styled.div`
  // width: 100%;
  display: flex;
  overflow: hidden;
  -webkit-box-align: center;
  align-items: center;
  border: 1px solid rgb(90, 178, 242);
  box-sizing: border-box;
  border-radius: 0.4rem;
  height: 2.5rem;
  font-size: 1.4rem;
  line-height: 2rem;
  padding-left: 0.2rem;
  margin-left: 2rem;
  margin-right: 2rem;
  margin-top: 1.5rem;
`
export const ExpressionList = styled.ul`
  margin-top: 0.5rem;
  border: 1px solid rgb(230, 230, 230);
  box-shadow: rgba(153, 153, 153, 0.3) 0px 2px 7px;
  border-radius: 4px;
  padding: 0.4rem 0px;
  width: 28rem;
  position: absolute;
  z-index: 9999;
  li {
    font-size: 0.7rem;
    line-height: 1rem;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    padding: 0.7rem 1.4rem;
  }
`
